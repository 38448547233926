/**
 * Created by tyb on 2016/11/28.
 */
import $ from 'jquery'

(function mobileHandler() {

  $(window).width() >= 768 ? $("header").removeClass("mobile-header") : $("header").addClass("mobile-header")

  $("#mm-menu-toggle").click(function (){
    var toTop = $(document).scrollTop();
    $(".am_offcanvas_content ").css("margin-top", toTop+30);
    document.getElementById("phoneNav").style.display = "block";
    var conH = $(".site-main").height();
    $("#wrapper").css("height",conH + 1000);
    document.getElementById("wrapper").style.display = "block";
    $("body").css("overflow-y","hidden");
    $(".toolBar a").css("color","black");
    document.ontouchmove = function(e){
      e.preventDefault();
    }
  });

  $("#wrapper").click(function (){
    document.getElementById("phoneNav").style.display = "none";
    document.getElementById("wrapper").style.display = "none";
    $("body").css("overflow-y","visible");
    $(".toolBar a").css("color","#F34205");
    document.ontouchmove = function(e){
      e.preventBubble();
    }
  });

  $("#mm-menu a").click(function (){
    document.getElementById("phoneNav").style.display = "none";
    document.getElementById("wrapper").style.display = "none";
    $("body").css("overflow-y","visible");
    $(".toolBar a").css("color","#F34205");
    document.ontouchmove = function(e){
      e.preventBubble();
    }
  });
}())

